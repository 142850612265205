<template>
  <div class="home max-height flex-column flex-justify-between">
    <div class=" flex-1  flex-center">
      欢迎使用Gooclin CTMS后台管理系统
    </div>
    <div class=" flex-0 flex-shrink-0">
      {{ 'V:'+msg.version+' - '+msg.date }}（页面：V：1.3.48 - 2023-08-03 14:30）
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      msg: {}
    }
  },
  mounted() {
    console.log(process.env.NODE_ENV)
    this.$http.get(this.$api.commonVersion, { data: '' }).then(res => {
      this.msg = res.result
    })
  },
  methods: {}
}
</script>
